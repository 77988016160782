html,
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue',
    sans-serif;
  font-size: 16px;
  line-height: 1.5;
  height: 100%;
  background: #ecf0f3;
}

#root {
  height: 100%;
}

:root {
  --main-bg-color: #4e98d6;
}

.main-navbar {
  padding: 0;
}

.pointer {
  cursor: pointer;
}

.btn-link {
  text-decoration: none !important;
}

nav.navbar {
  border-top: 4px solid #276cda;
  margin-bottom: 1rem;
}

.navbar-item.brand-text {
  font-weight: 300;
}

.navbar-item,
.navbar-link {
  font-size: 14px;
  font-weight: 700;
  color: #0f1d38 !important;
  font-size: 14px;
  font-weight: 700;
}

.columns {
  width: 100%;
  height: 100%;
  margin-left: 0;
}

.menu-label {
  color: #8f99a3;
  letter-spacing: 1.3;
  font-weight: 700;
}

.navbar-item:hover {
  background-color: #f2f2f2 !important;
}

.card {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.18);
  margin-bottom: 2rem;
}

.email-header-title {
  color: #8f99a3;
  font-weight: 400;
  padding: 0;
}

.info-tiles {
  margin: 1rem 0;
}

.info-tiles .subtitle {
  font-weight: 300;
  color: #8f99a3;
}

.hero.welcome.is-info {
  background: #36d1dc;
  background: -webkit-linear-gradient(to right, #5b86e5, #36d1dc);
  background: linear-gradient(to right, #5b86e5, #36d1dc);
}

.hero.welcome .title,
.hero.welcome .subtitle {
  color: hsl(192, 17%, 99%);
}

.card .content {
  font-size: 14px;
}

.card-footer-item {
  font-size: 14px;
  font-weight: 700;
  color: #8f99a3;
}

.card-table .table {
  margin-bottom: 0;
}

/*.events-card .card-table {*/
/*    max-height: 250px;*/
/*    overflow-y: scroll;*/
/*}*/

.create-import-view .select,
.create-import-view select {
  width: 100%;
}

.loader-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #fff;
  opacity: 0;
  z-index: -1;
  transition: opacity 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
}
.loader-wrapper .loader {
  height: 80px;
  width: 80px;
}
.loader-wrapper.is-active {
  opacity: 1;
  z-index: 1;
}

.is-loading {
  position: relative;
}

.primary-bg {
  background-color: var(--main-bg-color);
}

.pagination-active-page {
  border-bottom: 1px solid black;
}

.app-toast {
  z-index: 1;
  position: fixed;
  bottom: 2rem;
  right: 0;
}
